"use client";

import { useEffect, useState } from "react";
import { Header } from "@/components/common";
import { useTranslation } from "@/contexts/i18n";
import styles from "@/styles/404.module.scss";

export default function NotFound() {
  const { t } = useTranslation();
  const [displayedText, setDisplayedText] = useState("");
  const text = t("404, page not found.");

  useEffect(() => {
    let charIndex = 0;
    let currentText = "";

    const typeText = () => {
      if (charIndex < text.length) {
        currentText += text[charIndex];
        setDisplayedText(currentText);
        charIndex++;
        setTimeout(typeText, 100);
      }
    };

    typeText();
  }, [text]);

  return (
    <div className={`${styles["not-found__body"]}`}>
      <Header />
      <div className={styles["not-found__container"]}>
        <div className={styles["not-found__center"]}>
          <div className={styles["not-found__copy-container"]}>
            <div className={styles["not-found__text"]}>
              {displayedText}
              <span className={styles["not-found__cursor"]}></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
